<template>
  <div>
    <div class="row diag-steps mt-4" v-loading="isLoading">
      <draggable
        v-model="children"
        draggable=".diag-next-steps"
        :disabled="!editable || disableDraggable"
      >
        <div
          class="col-auto d-flex diag-next-steps"
          v-for="child in children"
          :key="child.id"
        >
          <ChildButton
            :node="child"
            :content="child"
            :parent="content"
            :root-id="rootId"
            :editable="editable"
            :options="options"
            @edit-label="disableDraggable = $event"
            @focus-node="handleFocusNode(child)"
            @relocate-child="relocateChild($event, child.id)"
            @update-content="$emit('update-content', $event)"
            @delete-content="$emit('delete-child', $event)"
          />
        </div>
        <base-button
          v-if="!editable && !isArchive"
          class="previous-button mt-2"
          outline
          type="primary"
          @click="handlePrevious"
        >
          <font-awesome-icon :icon="['far', 'arrow-left']"></font-awesome-icon>
        </base-button>
      </draggable>
    </div>
    <!-- ADD BTN -->
    <NewChildButton
      v-if="editable && options.newChild"
      :node-id="node.id"
      @add-child="addChild"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import draggable from 'vuedraggable';
import ChildButton from './ChildButton/ChildButton';
import NewChildButton from './NewChildButton';
import router from '@/router';

export default {
  name: 'diagnostic-builder-children',
  components: {
    draggable,
    ChildButton,
    NewChildButton,
  },
  props: {
    node: Object,
    rootId: String,
    editable: {
      type: Boolean,
      default: true,
    },
    options: Object,
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$root.$on('UPDATE_IS_LOADING', (val) => {
      this.isLoading = val;
    });
  },
  data() {
    return {
      disableDraggable: false,
      isLoading: false,
    };
  },
  computed: {
    children: {
      get() {
        if (this.options.isStaged)
          return this.node.children.filter(
            (child) => child.returnLanguage === this.navigationLanguage,
          );
        return this.node.children;
      },
      set(val) {
        this.isLoading = true;
        this.$emit('update-children-order', {
          id: this.node.id,
          payload: { path: val.map((o) => o.id) },
          source: 'focus',
        });
      },
    },
    isArchive() {
      return this.$route.name.includes('archive');
    },
    ...mapState('kbStore', ['treeNodes', 'nodesContent']),
    ...mapGetters('knowledgeModule', ['focusStep', 'navigationLanguage']),
  },
  methods: {
    relocateChild($event, childId) {
      const { newParent, onlyLocalUpdate } = $event;
      this.$emit('relocate-child', {
        childId,
        newParentId: newParent.id,
        formerParentId: this.node.id,
        onlyLocalUpdate,
      });
    },
    addChild(payload) {
      const { label } = payload;
      if (!label || !label.trim()) return;

      this.isLoading = true;
      this.$emit('add-child', payload);
    },
    async handleFocusNode(child) {
      return this.$emit('focus-node', child.id);
    },
    handlePrevious() {
      const { stepId } = this.$route.params;
      if (stepId) {
        return this.$emit(
          'focus-node',
          this.focusStep.ancestors[0].split('/').at(-1),
        );
      }
      return router.push({ name: 'web-filter' });
    },
    ...mapActions({
      updateTreeNode: 'SOCKET_UPDATED_TREE_NODE',
    }),
  },
  watch: {
    children(newVal, oldVal) {
      if (newVal.length !== oldVal.length) this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.diag-steps {
  display: block;
}

.previous-button {
  margin-left: 15px;
}
</style>
