import articleIcon from '@/assets/article-icon-v2.svg';
import diagIcon from '@/assets/diag_icon.svg';

export const constantTypes = ['entity', 'type', 'status'];

const filters = [
  {
    id: 'content',
    key: 'entity',
    value: 'attached-contents',
    label: 'knowledge.search-admin.content',
    icon: 'file-alt',
    needTrad: true,
  },
  {
    id: 'product',
    key: 'entity',
    value: 'new-products',
    label: 'knowledge.search-admin.product',
    icon: 'barcode-scan',
    needTrad: true,
  },
  {
    id: 'Article',
    key: 'type',
    value: 'Article',
    label: 'knowledge.search-admin.article',
    iconUrl: articleIcon,
    color: '#fcbf49',
    needTrad: true,
  },
  {
    id: 'Diagnostic',
    key: 'type',
    value: 'Diagnostic',
    label: 'knowledge.search-admin.diagnostic',
    iconUrl: diagIcon,
    color: '#0081f9',
    needTrad: true,
  },
  {
    id: 'draft',
    key: 'published',
    value: 'false',
    label: 'knowledge.search-admin.draft',
    icon: 'ruler-triangle',
    color: '#adb5bd',
    needTrad: true,
  },
  {
    id: 'published',
    key: 'published',
    value: 'true',
    label: 'knowledge.search-admin.published',
    icon: 'file-check',
    color: '#0081f9',
    needTrad: true,
  },
  {
    id: 'checked',
    key: 'isOutdated',
    value: 'false',
    label: 'knowledge.search-admin.verified',
    icon: 'check-circle',
    color: '#67c23a',
    needTrad: true,
  },
  {
    id: 'warning',
    key: 'isOutdated',
    value: 'true',
    label: 'knowledge.search-admin.to-be-verified',
    icon: 'times-circle',
    color: '#fd0044',
    needTrad: true,
  },
];

export function getFilterByKey(keys) {
  return filters.reduce((acc, val) => {
    if (keys.includes(val.key)) acc.push(val);
    return acc;
  }, []);
}

export function resolveSuggestFilter({ type, value }) {
  if (constantTypes.includes(type)) {
    return filters.find((f) => f.id === value.id);
  } else {
    switch (type) {
      case 'knowledge':
        return {
          id: value.documentId,
          key: 'knowledge',
          label: value.label,
        };
      case 'category':
        return {
          categoryName: value.categoryName,
          color: value.color,
          icon: value.icon,
          id: value.documentId,
          key: 'categories',
          label: value.labelName,
          labelName: value.labelName,
          value: value.documentId,
        };
      default:
        null;
    }
  }
}
