<template>
  <base-dropdown>
    <div
      slot="title"
      class="dropdown dropdown-button d-flex align-items-center"
    >
      <font-awesome-icon
        :icon="['fal', 'ellipsis-h']"
        class="options_selection"
      />
    </div>
    <div>
      <!-- RENAME -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div class="dropdown-item">
            <h5
              class="text-overflow m-0"
              @click="hasPermissions ? $emit('edit-label') : ''"
            >
              <font-awesome-icon
                :icon="['fad', 'edit']"
                class="edit-node-icon"
              />
              <small class="ml-2">{{ $t('knowledge.actions.rename') }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- CLONE -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canCreateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div class="dropdown-item">
            <h5
              class="text-overflow m-0"
              @click="hasPermissions ? $emit('clone-kb') : ''"
            >
              <font-awesome-icon
                :icon="['fad', 'copy']"
                class="edit-node-icon"
              />
              <small class="ml-2">{{
                $t('knowledge.actions.clone-in-other-kb')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- DUPLICATE IN SAME LOCATION -->
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canCreateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item"
            @click="hasPermissions ? $emit('duplicate-in-same-location') : ''"
          >
            <h5 class="text-overflow m-0">
              <font-awesome-icon
                :icon="['fad', 'object-group']"
                class="option-icon"
              />
              <small class="ml-2">{{
                $t('knowledge.actions.duplicate-in-same-location')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- CHANGE ICON -->
      <not-allowed-tooltip
        v-if="isRoot"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item"
            @click="hasPermissions ? $emit('update-icon') : ''"
          >
            <h5 class="text-overflow m-0">
              <font-awesome-icon
                :icon="['fad', 'image-polaroid']"
                class="text-grey"
              />
              <small class="ml-2">{{
                $t('knowledge.actions.change-icon')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- MINI ICON -->
      <not-allowed-tooltip
        v-if="isRoot"
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item"
            @click="hasPermissions ? $emit('update-mini-icon') : ''"
          >
            <h5 class="text-overflow m-0">
              <font-awesome-icon
                :icon="['fad', 'image-polaroid']"
                class="text-grey"
              />
              <small class="ml-2">{{
                $t('knowledge.actions.change-mini-icon')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>

      <!-- UPDATE VERIFICATION -->
      <div class="dropdown-item" v-if="type && !isOutdated">
        <h5
          class="text-overflow m-0"
          @click="$emit('update-content-verification', true)"
        >
          <font-awesome-icon
            :icon="['fad', 'times-circle']"
            class="edit-node-icon"
          />
          <small class="ml-2">{{ $t('knowledge.actions.outdate') }}</small>
        </h5>
      </div>

      <!-- DELETE / ARCHIVE -->
      <div class="dropdown-divider"></div>
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item"
            :class="hasPermissions ? 'archive-item' : 'archive-disabled'"
          >
            <h5
              class="text-overflow m-0"
              @click="hasPermissions ? $emit('delete-node') : ''"
            >
              <font-awesome-icon
                :icon="['fad', 'archive']"
                class="edit-node-icon text-red"
              />
              <small v-if="type" class="ml-2">{{
                $t('knowledge.actions.archive')
              }}</small>
              <small v-else class="ml-2">{{
                $t('knowledge.actions.delete')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canDeleteContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div
            class="dropdown-item"
            :class="hasPermissions ? ' soft-delete' : 'soft-delete-disabled'"
          >
            <h5
              class="text-overflow m-0"
              @click="
                hasPermissions
                  ? $emit('delete-node', { softDeleted: true })
                  : ''
              "
            >
              <font-awesome-icon
                :icon="['fad', 'trash']"
                class="edit-node-icon"
              />
              <small class="ml-2">{{
                $t('knowledge.actions.soft-delete')
              }}</small>
            </h5>
          </div>
        </template>
      </not-allowed-tooltip>
    </div>
  </base-dropdown>
</template>
<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
export default {
  name: 'edit-node-options',
  components: {
    NotAllowedTooltip,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    isOutdated: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFolder() {
      return !this.type;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  padding: 0px;
}

.dropdown-button {
  border-width: 0px;
  box-shadow: none;
  margin: auto;
}

.pointer {
  cursor: pointer;
}

.archive-item:hover {
  background-color: rgba($yellow-mayday, 0.1);
}

.soft-delete {
  background-color: rgba($red-mayday, 0.1);
  small,
  .edit-node-icon {
    color: $red-mayday;
  }
  &:hover {
    background-color: $red-mayday;
    small,
    .edit-node-icon {
      color: white;
    }
  }
}

.edit-node-icon {
  color: #adb5bd;
  max-width: 14px;
}

.options_selection {
  width: 16px;
  color: #adb5bd;
  cursor: pointer;
}

.options_selection:hover {
  color: $blue-mayday;
}

.dropdown-item.archive-disabled {
  & small {
    color: $grey-5-mayday;
  }
  &:hover {
    background-color: transparent !important;
  }
}

.dropdown-item.soft-delete-disabled {
  background-color: $grey-1-mayday;
  & small {
    color: $grey-5-mayday;
  }
  & .edit-node-icon {
    color: $red-mayday;
  }
}

:deep() #dropdown {
  padding-bottom: 0;
}
</style>
