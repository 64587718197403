<template>
  <div class="w-full">
    <div
      :class="[
        'row',
        'editor-navigator-vue-header',
        { 'mx-0': !isFocusArchive },
        { 'red-banner': isFocusArchive },
        { 'justify-content-center': isFocusArchive },
        { 'sticky-top': isFocusArchive },
      ]"
    >
      <div
        v-if="!isFocusArchive"
        class="d-flex flex-row header-panel align-items-center justify-content-between"
      >
        <Breadcrumb
          v-if="isCasePathNavigatorDisplayable"
          :ajustable-margin="40"
          :clickable="true"
          :isTemplate="routeType === 'template'"
          :items="pathToDisplay"
          :show-house="true"
        ></Breadcrumb>

        <div v-else class="flex-grow-1">
          <div v-if="isResultsFilterPage" class="page-title">
            {{ $t('knowledge.search-admin.search-results') }}
          </div>
        </div>
        <div
          v-if="isContent && hasStagedVersion"
          class="d-inline-flex align-items-center flex-none"
        >
          <span class="switch-label">
            {{ $t('knowledge.staged-version.title') }}
          </span>
          <el-switch
            v-model="isStagedContent"
            @change="switchStagedVersion"
            size="mini"
            class=""
          ></el-switch>
        </div>
        <div
          class="pr-2 flex-none d-flex flex-row-reverse align-items-center justify-content-between ml-auto"
        >
          <div class="options-header d-flex justify-content-end">
            <ConnectedUsers
              v-if="isContent"
              :content-id="focusContent.id"
              :lang="editingLanguage || 'default'"
            />

            <!-- SEARCH -->
            <SearchField
              v-if="isCase"
              :translate="{
                placeholder: $t('knowledge.product.search-placeholder'),
                tooltip: $t('knowledge.product.search-tooltip'),
              }"
              :updated-value="collectionSearchFilter"
              @launch-search="$emit('launch-search', $event)"
            />
            <!-- IMPORT -->
            <not-allowed-tooltip
              :user-permissions="userPermissions"
              permission-key="canCreateContent"
              placement="bottom"
            >
              <template v-slot:main-content="{ hasPermissions }">
                <div
                  v-if="isCase"
                  placeholder="import a word document"
                  @click="hasPermissions ? (modals.importFiles = true) : ''"
                >
                  <el-tooltip
                    :content="$t('knowledge.actions.import-files')"
                    :disabled="!hasPermissions"
                  >
                    <span class="import-files__icon-wrapper">
                      <font-awesome-icon
                        :icon="['fal', 'file-import']"
                        class="import-files__icon"
                      />
                    </span>
                  </el-tooltip>
                </div>
              </template>
            </not-allowed-tooltip>
            <!-- ELLIPSIS DROPDOWN -->
            <CaseHeaderOptions
              v-if="isCase || isContent || isTemplate"
              :options="headerOptions"
              :user-permissions="userPermissions"
              @archive="$emit('archive', $event)"
              @open-delete-modal="$emit('open-delete-modal', $event)"
              @open-clone-modal="$emit('open-clone-modal', $event)"
              @open-content-version="$emit('open-content-version', $event)"
              @open-content-settings="$emit('open-content-settings', $event)"
              @open-integration-modal="$emit('open-integration-modal')"
              @create-staged-version="$emit('create-staged-version')"
              @delete-staged-version="$emit('delete-staged-version')"
              @update-icon="modals.editIcon = true"
              @update-mini-icon="modals.editMiniIcon = true"
            />
          </div>
        </div>
      </div>
      <ArchiveViewerHeader v-else />
      <ImportFiles
        v-if="modals.importFiles"
        :display="modals.importFiles"
        :is-loading="importFilesIsLoading"
        @close="modals.importFiles = false"
        @importFiles="importFiles"
      />
      <IconEditionModal
        v-if="modals.editIcon || modals.editMiniIcon"
        :current-icon="focusedIcon"
        :display="modals.editIcon || modals.editMiniIcon"
        @choice="handleUpdateIcon"
        @close="handleCloseIconModals"
        @delete="handleDeleteIcon"
      />
    </div>
    <BaseCallout
      v-if="displayConnectedUsersAlert"
      style="width: 70%"
      :text="$t('knowledge.contributors.multiple-editor-warning')"
    />
  </div>
</template>

<script>
import SearchField from '@/components/SearchField';
import CaseHeaderOptions from '@/components/Commons/CasesNavigator/CaseHeaderOptions';
import ArchiveViewerHeader from './ArchiveViewerHeader';
import ImportFiles from '@/components/Modals/ImportFiles';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import Breadcrumb from '../../../components/SuperSearch/Breadcrumb.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import ConnectedUsers from 'views/KnowledgeEditor/ConnectedUsers.vue';
import BaseCallout from '@/components/BaseCallout.vue';
import IconEditionModal from 'components/Modals/IconEditionModal/IconEditionModal.vue';

export default {
  name: 'ParametricKnowledgeEditorHeader',
  components: {
    IconEditionModal,
    ConnectedUsers,
    SearchField,
    CaseHeaderOptions,
    ArchiveViewerHeader,
    ImportFiles,
    NotAllowedTooltip,
    Breadcrumb,
    BaseCallout,
  },
  props: {
    casePath: {
      type: Array,
      default: () => [],
    },
    routeType: {
      type: String,
    },
    collectionSearchFilter: {
      type: String,
      default: '',
    },
    isSettingsDrawerOpen: {
      type: Boolean,
    },
    hasStagedVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importFilesIsLoading: false,
      modals: {
        importFiles: false,
        editIcon: false,
        editMiniIcon: false,
      },
      isStagedContent: false,
    };
  },
  watch: {
    '$route.query': {
      handler: function (query) {
        const { staged } = query;
        this.isStagedContent = !!staged;
      },
      immediate: true,
    },
  },
  computed: {
    isFocusArchive() {
      return [
        'knowledge-archive',
        'knowledge-case-archive',
        'knowledge-case-archive-content',
      ].includes(this.$route.name);
    },
    pathToDisplay() {
      return this.isTemplate ? this.templatesPath : this.casePath;
    },
    isCasePathNavigatorDisplayable() {
      return [
        'web-content',
        'web-content-step',
        'knowledge-templates',
        'knowledge-template',
        'knowledge-case',
        'knowledge-content',
        'knowledge-content-step',
      ].includes(this.$route.name);
    },
    isResultsFilterPage() {
      const path = this.$route.path.split('/');
      return path[2] === 'filter';
    },
    isCase() {
      return this.$route.path.split('/').includes('case');
    },
    isContent() {
      return this.$route.path.split('/').includes('content');
    },
    isArticle() {
      return (
        this.isContent &&
        !!this.focusContent &&
        this.focusContent.type === 'Article'
      );
    },
    isDiagnosticRoot() {
      return (
        this.isContent &&
        !!this.focusContent &&
        this.focusContent.type === 'Diagnostic' &&
        !this.$route.path.split('/').includes('step')
      );
    },
    isTemplate() {
      return this.$route.path.includes('templates');
    },
    isRootCase() {
      return !!this.focusCollection && !!this.focusCollection.root;
    },
    hasImportedIntegration() {
      return this.hasZendesk || this.hasNotion || this.hasSalesforce;
    },
    headerOptions() {
      return {
        contentSettings: this.isContent && !this.isStagedContent,
        copyToClipboard: !this.isParametric,
        deleteCase: this.isCase,
        cloneCase: this.isCase && !this.isParametric,
        deleteContent: this.isContent && !this.isStagedContent,
        archiveContent: this.isContent && !this.isStagedContent,
        deleteStagedVersion: this.isContent && this.isStagedContent,
        versionContent: this.isContent && !this.isStagedContent,
        integrationImport:
          this.isArticle &&
          this.hasImportedIntegration &&
          !this.isStagedContent,
        settings: this.isContent && !this.isStagedContent,
        createTemplate:
          this.isContent && !this.isParametric && !this.isStagedContent,
        isSettingsDrawerOpen:
          this.isSettingsDrawerOpen && !this.isStagedContent,
        createStagedVersion: this.isContent && !this.hasStagedVersion,
        editIcons: this.isParametric && this.isRootCase,
      };
    },
    userPermissions() {
      if (this.isCase) {
        return this.focusCollection ? this.focusCollection.userPermissions : {};
      } else {
        return this.focusContent ? this.focusContent.userPermissions : {};
      }
    },
    displayConnectedUsersAlert() {
      return (
        this.focusContent &&
        this.connectedUsers &&
        this.connectedUsers[
          `${this.focusContent.id}_${this.editingLanguage}`
        ] &&
        !!this.connectedUsers[`${this.focusContent.id}_${this.editingLanguage}`]
          .length
      );
    },
    focusedIcon() {
      let currentIcon = 'icon';
      if (this.modals.editMiniIcon) currentIcon = 'miniIcon';
      return this.focusCollection[currentIcon] &&
        this.focusCollection[currentIcon].iconType === 'fa'
        ? this.focusCollection[currentIcon]
        : { iconType: 'fa', value: 'folder', color: null };
    },
    ...mapGetters(['hasZendesk', 'hasNotion', 'hasSalesforce', 'isParametric']),
    ...mapGetters('knowledgeModule', [
      'focusContent',
      'focusCollection',
      'templatesPath',
      'editingLanguage',
    ]),
    ...mapState('adminModule', ['connectedUsers']),
  },
  methods: {
    async importFiles(files) {
      this.modals.importFiles == false;
      const currentCasePath = this.casePath;
      const caseId = currentCasePath.slice(-1)[0].id;
      if (files.length) {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append('files', file);
        }
        formData.append('caseId', caseId);
        formData.append('lang', this.editingLanguage);
        try {
          this.importFilesIsLoading = true;
          const res = await this.$services.parametricContents.importFiles(
            formData,
          );

          this.importFilesIsLoading = false;
          this.modals.importFiles = false;
          const contents = res.data.createdContents.map((c) => {
            const language = c.translations[this.editingLanguage]
              ? this.editingLanguage
              : c.defaultLanguage;
            const translations = c.translations[language];
            return {
              id: c._id,
              label: translations.label,
              body: translations.body,
              returnLanguage: language,
              setLanguages: [language],
              ...c,
            };
          });
          this.$message({
            message: this.$tc(
              'knowledge.article-builder.side-bar.imported-docx.notification.content',
              contents.length,
            ),
            type: 'success',
          });
          this.insertImportedFilesInCollection(contents);
          return contents;
        } catch (err) {
          this.$message({
            message: this.$tc(
              'knowledge.article-builder.side-bar.imported-docx.notification.error-title',
              this.files.length,
            ),
            type: 'error',
          });
          this.importFilesIsLoading = false;
          this.modals.importFiles = false;
          return err;
        }
      } else {
        return 'pas de fichier';
      }
    },
    switchStagedVersion() {
      // eslint-disable-next-line no-unused-vars
      const { staged, ...queryWithoutStaged } = this.$route.query;
      const route = {
        path: this.$route.path,
        query: {
          ...queryWithoutStaged,
          ...(this.isStagedContent ? { staged: true } : {}),
        },
      };
      this.$router.replace(route);
    },
    handleCloseIconModals() {
      this.modals.editIcon = false;
      this.modals.editMiniIcon = false;
    },
    async handleDeleteIcon() {
      await this.deleteCaseIcon({
        caseId: this.focusCollection.id,
        field: this.modals.editMiniIcon ? 'miniIcon' : 'icon',
      });
      this.handleCloseIconModals();
    },
    async handleUpdateIcon(icon) {
      try {
        await this.updateCaseIcon({
          caseId: this.focusCollection.id,
          icon: icon,
          field: this.modals.editMiniIcon ? 'miniIcon' : 'icon',
        });
        this.$message({
          message: this.$t('knowledge.actions.notify.update-success'),
          type: 'success',
        });
      } catch (e) {
        this.$message({
          message: this.$t('knowledge.actions.notify.update-failure'),
          type: 'error',
        });
      }
      this.handleCloseIconModals();
    },
    ...mapActions('knowledgeModule', [
      'insertImportedFilesInCollection',
      'updateCaseIcon',
      'deleteCaseIcon',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.editor-navigator-vue-header {
  background-color: white;
  height: 60px;
}

.header-panel {
  width: 100%;
}

.page-title {
  font-size: 12px;
  color: $grey-6-mayday;
  font-weight: 600;
}

.red-banner {
  background-color: #f5365c !important;
  padding-left: 0;
  padding-right: 0;
}

.breadcrumb-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.options-header {
  div {
    margin: 0 4px;
  }
}
.import-files__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  color: $grey-7-mayday;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: $grey-2-mayday;
  }
  .import-files__icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}

.flex-none {
  flex: none;
}

.switch-label {
  font-size: 14px;
  margin-right: 10px;
  color: $grey-9-mayday;
}
</style>
