<template>
  <div class="content-version-history">
    <div class="bg-white sticky-top">
      <div class="version-title">
        {{ $t('components.modals.content-version.title') }}
      </div>
      <p class="mb-0 version-subtitle">
        {{ $t('components.modals.content-version.description') }}
      </p>
      <slot></slot>
    </div>
    <div>
      <div
        v-for="(version, idx) in contentVersions"
        :key="version.id"
        class="content-version"
        :class="{ selected: idx === focusedVersionIdx }"
        @click="$emit('choose', idx)"
      >
        <div class="version">
          <p class="mb-1">
            <small>⏱️ {{ version.formattedDate }}</small>
          </p>
          <p class="mb-1">
            <small>🖊️ {{ version.author }}</small>
          </p>
        </div>
        <el-tooltip
          v-if="version.isStagedDataPublish"
          class="tooltip-wrapper"
          placement="top"
          :content="
            $t('components.modals.content-version.staged-pushed-tooltip')
          "
        >
          <font-awesome-icon
            class="staged-push-icon"
            :icon="['fa', 'info-circle']"
          />
        </el-tooltip>
      </div>
      <base-button
        class="col-12"
        outline
        type="secondary"
        @click="$emit('show-more')"
        v-if="contentVersions.length < total"
        >{{ $t('components.modals.content-version.show-more') }}</base-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-version-history',
  props: {
    contentVersions: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    focusedVersionIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showAllVersions: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.staged-push-icon {
  color: $grey-4-mayday;

  &:hover {
    color: $blue-5-mayday;
  }
}
.version {
  flex-grow: 1;
}
.version-title {
  font-size: 20px;
  font-weight: 600;
}

.version-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}

.content-version {
  display: flex;
  padding: 4px;
  border-radius: 2px;
}

.content-version-history {
  height: 60vh;
  overflow: auto;
}

.content-version:hover {
  background-color: $grey-1-mayday;
  cursor: pointer;
}

.selected {
  background-color: $grey-2-mayday;
}

.tooltip-wrapper {
  height: fit-content;
}
</style>
