<template>
  <div v-if="(!emptyBody || editable) && !isNotStagedContentEditable">
    <div class="diagnostic-editor-card mt-4">
      <img
        v-if="emptyBody && editable"
        class="diagnostic-message-editor-empty-state"
        :src="emptyStateSrc"
        @click="editContent"
      />
      <MaydayEditorCEWrapper
        v-if="!emptyBody"
        :key="editorKey"
        class="editor-ce-wrapper"
        :body="editorBody"
        :editable="editable"
        :locale="$i18n.locale"
        :focus-editor="true"
        @update-written-content="delayedUpdateContent"
        @generate-summary="$emit('generate-summary')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaydayEditorCEWrapper from 'components/Editors/MaydayEditorCEWrapper';

export default {
  name: 'diagnostic-builder-editor-card',
  components: {
    MaydayEditorCEWrapper,
  },
  props: {
    content: Object,
    editable: {
      type: Boolean,
      default: true,
    },
    appLanguage: {
      type: String,
      default: 'fr',
    },
    isStagedContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyBody: true,
    };
  },
  mounted() {
    this.computeIsEmptyBody(this.content.body);
  },
  computed: {
    emptyStateSrc() {
      return require(`assets/${this.appLanguage}-diag-placeholder.png`);
    },
    editorKey() {
      return `${this.content.id}-${this.content.returnLanguage}-${
        this.content.forceRefreshCount
      }-${this.isStagedContent ? '1' : '0'}-${
        this.content.stagedData ? '1' : '0'
      }`;
    },
    editorBody() {
      if (this.isStagedContent && this.content.stagedData)
        return this.content.stagedData.body;
      return this.content.body;
    },
    isNotStagedContentEditable() {
      return (
        this.isStagedContent &&
        this.content.returnLanguage !== this.navigationLanguage
      );
    },
    ...mapGetters('knowledgeModule', ['navigationLanguage']),
  },
  methods: {
    editContent() {
      this.emptyBody = false;
    },
    computeIsEmptyBody(body) {
      this.emptyBody =
        !body ||
        body === '<p></p>' ||
        !!body.match(/^<p data-uid="[^"]+"><\/p>$/);
    },
    // DEBOUNCE CONTENT UPDATE
    delayedUpdateContent(newContent) {
      this.$emit('delayed-update-content', {
        id: this.content.id,
        payload: newContent,
      });
    },
  },
  watch: {
    content: {
      handler(newVal) {
        this.computeIsEmptyBody(newVal.body);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.diagnostic-editor-card {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 24px;
}

.diagnostic-message-editor-empty-state {
  width: 100%;
  &:hover {
    box-shadow: 0px 10px 30px #7090b026;
    cursor: pointer;
  }
}

.editor-ce-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
